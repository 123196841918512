@import "src/colors";

.wrapperBox {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 115px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .textBox {
    color: $feedbacks-grey;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .footerBox {
    flex-shrink: 0;
  }
}

