@import "colors";

body {
    background: #fff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

form {
    display: flex;
    flex-direction: column;
    width: 50%;

    @media screen and (max-width: 991px) {
        width: 80%;
    }
}

h1 {
    font-weight: 100;
    color: $feedbacks-grey;
    text-align: center;
    padding-bottom: 10px;
}

p {
    color: $feedbacks-grey;
}

a {
    color: $feedbacks-primary-color;
    text-decoration: none;
}

label {
    margin: 0 0 12px 0;
}

label.questionLabel {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 16px !important;
    margin-top: 20px !important;
    color: $feedbacks-grey;
    font-size: 16px;
    //font-weight: 200;
}

button.submit-btn {
    background: $feedbacks-primary-color;
    border: 1px solid $feedbacks-primary-color;
    border-radius: 4px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: block;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 10px;
    margin-top: 2rem;
    padding: 10px 15px;
    resize: vertical;
    text-transform: uppercase;
    width: 100%;
    transition: 0.3s all;
}

button.submit-btn:hover,
button.submit-btn:focus {
    background: #fff;
    color: $feedbacks-primary-color;
}

button.submit-btn:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
}

button.submit-btn:disabled {
    opacity: 0.4;
}

hr {
    margin-top: 30px;
}

span.errorsWrapper {
    color: $feedbacks-error-color;
    margin: 0 0 2rem 0;
}

.appWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .formTitle {
        text-align: center;
        width: 55%;

        @media screen and (max-width: 991px) {
            width: 80%;
        }
    }

    form.formWrapper {
        margin-bottom: 64px;
    }
}
