@import "src/colors";

.footerMainRow {
  background-color: $feedbacks-primary-color;

  .footerCol {
    width: 33% !important;
    box-shadow: none !important;

    .footerColTitle {
      font-size: 1.8em;
      font-weight: bolder;
    }

    .footerLogo {
      margin: 24px 0;
    }

    a {
      font-weight: bolder;
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }

    p:nth-last-child(1n) {
      margin: 24px 0 0 0 !important;
    }

    span {
      color: #fff;
    }
  }
  .footerLastCol {
    text-align: center;
    width: 100% !important;

    a {
      color: #fff;
      margin: 0 6px;
      font-weight: 600;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

