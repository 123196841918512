@import "src/colors";

.editorBody {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid $feedbacks-primary-color;
  padding: 10px 15px;
  font-size: 14px;
  margin: 0 0 .5rem 0;
  resize: vertical;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
}

.editorBody:focus,
.editorBody:active,
.editorBody:focus-visible {
  outline: 1px solid #fff;
  box-shadow: 0 0 0 2px $feedbacks-primary-color;
}