div.pageWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  div.policyWrapper {
    text-align: justify;
    padding: 16px 64px 32px 64px;
    line-height: 2rem;
    width: auto;
    max-width: 900px;
    min-height: 50vh;

    p {
      margin-bottom: 40px;
    }

    .centered {
      text-align: center;
    }

    ol {
      margin-top: 0 !important;
    }

    ol.numberedList {
      list-style: none;
    }

    li.numberedListItem {
      display: table-row;
    }

    li.numberedListItem:before {
      content: attr(value)".";
      display: table-cell;
      padding-right: 5px;
    }
  }
}


